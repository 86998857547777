<template>
  <header id="header">
    <div class="header-nav-container">
      <div uk-sticky="sel-target: .sticky-inner; cls-active: uk-navbar-sticky">
        <div class="sticky-inner">
          <div class="header-nav-desktop" v-if="screenWidth >= 992">
            <div class="uk-container">
              <div class="container-inner" uk-navbar>
                <div class="logo-container">
                  <router-link to="/" :class="['home-link', (this.$route.path === '/' ? 'active' : '')]" :title="`Navigate to the home page`">
                    <img src="@/assets/Badal_by_TELUS_Business.png"/>
                  </router-link>
                </div>
                <div class="nav-items uk-navbar-right">
                  <ul class="uk-navbar-nav" id="header-nav">
                    <li><router-link to="/about">About Us</router-link></li>
                    <li><router-link to="/services">Services</router-link>
                      <div class="uk-navbar-dropdown">
                        <ul class="uk-nav uk-navbar-dropdown-nav">
                          <li><router-link to="/services/cloud-solutions">Infrastructure Modernization</router-link></li>
                          <li><router-link to="/services/bigdata-analytics">Data Analytics</router-link></li>
                        </ul>
                      </div>
                    </li>
                    <li><router-link to="/work">Work</router-link></li>
                    <li><router-link to="/blogs">Blogs</router-link></li>
                    <li><router-link to="/careers">Careers</router-link></li>
                    <li><router-link to="/contact">Contact Us</router-link></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="header-nav-mobile" v-else>
            <div class="uk-container">
              <div class="menu-wrapper">
                <div class="logo-container">
                  <router-link to="/" :class="['home-link', (this.$route.path === '/' ? 'active' : '')]" :title="`Navigate to the home page`">
                    <img src="@/assets/flavicon-03.png" />
                    <span>Badal</span>
                  </router-link>
                </div>

                <div class="nav-select-container">
                  <div class="menu-text">MENU</div>
                  <a class="uk-button uk-button-default" href="#mobile-nav-modal" uk-toggle>
                    <span uk-navbar-toggle-icon></span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="mobile-nav-modal" class="uk-modal-full" uk-modal>
      <div class="uk-modal-dialog">
        <div class="uk-modal-header">
          <div class="logo-container">
            <router-link to="/" :class="['home-link', (this.$route.path === '/' ? 'active' : '')]" v-on:click.native="closeModal()" :title="`Navigate to the home page`" >
              <img src="@/assets/BadalLogo-01.png" />
            </router-link>
          </div>

          <button class="uk-modal-close-full uk-close-large" type="button" uk-close></button>
        </div>

        <div class="uk-modal-body">
          <div class="nav-items">
            <router-link to="/" v-on:click.native="closeModal()">Home</router-link>
            <router-link to="/about" v-on:click.native="closeModal()">About Us</router-link>
            <router-link to="/services" v-on:click.native="closeModal()">Services</router-link>

            <ul class="uk-nav">
              <li><router-link to="/services/cloud-solutions" v-on:click.native="closeModal()">Infrastructure Modernization</router-link></li>
              <li><router-link to="/services/bigdata-analytics" v-on:click.native="closeModal()">Data Analytics</router-link></li>
            </ul>

            <router-link to="/work" v-on:click.native="closeModal()">Work</router-link>
            <router-link to="/blogs" v-on:click.native="closeModal()">Blogs</router-link>
            <router-link to="/careers" v-on:click.native="closeModal()">Careers</router-link>
            <router-link to="/contact" v-on:click.native="closeModal()">Contact Us</router-link>
          </div>
        </div>

      </div>
    </div>
  </header>
</template>

<script>
import UIkit from 'uikit';

export default {
  name: 'Header',
  data () {
    return {
      screenWidth: 0,
      modal: null
    }
  },
  created () {
    this.screenWidth = this.getScreenWidth()

    // Check width on window resize
    window.onresize = () => {
      this.screenWidth = this.getScreenWidth()
    }
  },
  mounted () {
    this.modal = document.getElementById('mobile-nav-modal');
  },
  methods: {
    getScreenWidth () {
      return Math.max(
        document.body.scrollWidth,
        document.documentElement.scrollWidth,
        document.body.offsetWidth,
        document.documentElement.offsetWidth,
        document.documentElement.clientWidth
      );
    },
    closeModal () {
      UIkit.modal(this.modal).hide()
    }
  }
}
</script>