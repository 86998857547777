<template>
  <div id="contact-page" class="page-view">
    <page-hero 
      :pageTitle="pageTitle" 
      :titleStatement="titleStatement">
    </page-hero>

    <social-side-bar></social-side-bar>

    <div class="contact-form-container" id="contact-form">
      <div class="uk-container">
        <div class="contact-flexbox">
          <div class="main-content-left">
            <h2>Contact Us</h2>

            <form v-on:submit.prevent="formHandler()">
              <div class="uk-margin">
                <label class="uk-form-label" for="contact-name">Name</label>
                <input
                  id="contact-name" 
                  class="uk-input uk-form-width-large" 
                  type="text" 
                  placeholder="Name" 
                  v-model="formData.name"
                  required="true"
                >
              </div>

              <div class="uk-margin">
                <label class="uk-form-label" for="contact-email">Email</label>
                <input 
                  id="contact-email"
                  class="uk-input uk-form-width-large" 
                  type="email" 
                  placeholder="Email" 
                  v-model="formData.email"
                  required="true"
                >
              </div>

              <div class="uk-margin">
                <label class="uk-form-label" for="contact-phone">Phone</label>
                <input
                  id="contact-phone" 
                  class="uk-input uk-form-width-large" 
                  type="phone" 
                  placeholder="Phone" 
                  v-model="formData.phone"
                  required="true"
                >
              </div>

              <div class="uk-margin">
                <label class="uk-form-label" for="contact-subject">Subject</label>
                <input 
                  id="contact-subject"
                  class="uk-input uk-form-width-large" 
                  type="text" 
                  placeholder="Subject" 
                  v-model="formData.subject"
                  required="true"
                >
              </div>

              <div class="uk-margin">
                <label class="uk-form-label" for="contact-comments">Comments</label>
                <textarea 
                  id="contact-comments"
                  class="uk-textarea" 
                  v-model="formData.message"
                  required="true">
                </textarea>
              </div>

              <div class="controls">
                <button class="form-submit-btn">Submit</button>
              </div>
            </form>
            <div v-if="emailSent" class="controls">
                <p class="email-confirmation">We received your email!</p>
              </div>
          </div>

          <div class="main-content-right">
            <div class="content-block-container">
              <div class="content-block">
                <div class="icon">9</div>
                <div class="content">
                  <div class="content-inner">
                    <h3>Our location</h3>
                    <p>25 York Street<br>
                      Toronto, Ontario <br>
                      Canada <br>
                      M5J 2V5</p>
                  </div>
                </div>
              </div>

              <div class="content-block">
                <div class="icon">0</div>
                <div class="content">
                  <div class="content-inner">
                    <h3>Email</h3>
                    <p><a href="mailto:info@badal.io">info@badal.io</a></p>
                  </div>
                </div>
              </div>

              <div class="content-block">
                <div class="icon">a</div>
                <div class="content">
                  <div class="content-inner">
                    <h3>Hours of operation</h3>
                    <p>Monday - Friday: 7am - 6pm <br>
                      Saturday: closed <br>
                      Sunday: closed</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="content-block testimonial-container">
              <div class="testimonial">
                <p>A Google cloud partner. Badal helps with workload migration to cloud based services and can change an enterprise’s IT landscape, tapping into the scale and agility of cloud.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHero from '@/layouts/PageHero.vue'
import SocialSideBar from '../layouts/SocialSideBar.vue'

export default {
  metaInfo() {
    return {
      title: 'Contact Us',
      meta: [
        { name: 'description', content: `${this.titleStatement}`},
        { name: 'robots', content: 'index, follow'},
        { property: 'og:type', content: 'article'},
        { property: 'og:title', content: `${this.pageTitle}`},
        { property: 'og:description', content: `${this.titleStatement}`},
        { property: 'og:url', content: `${this.$route.path}`},
        { property: 'og:site_name', content: 'BADAL'},
        { property: 'twitter:title', content: `${this.pageTitle}`},
        { property: 'twitter:description', content: `${this.titleStatement}`},
        { property: 'twitter:site', content: 'BADAL'},
      ]
    }
  },
  components: { 
    PageHero,
    SocialSideBar
  },
  name: 'Contact',
  data () {
    return {
      pageTitle: 'Start a conversation today',
      titleStatement: 'Interested in how Badal can help your business reach a higher level? Fill out the form below and someone from the Badal team will reach out to you shortly.',
      formData: {
        to: '',
        from: '',
        name: '',
        email: '',
        phone: '',
        subject: '',
        message: ''
      },
      axios: require('axios'),
      emailSent:false
    }
  },
  created () {
    this.formData.to = this.$toEmailAddress
    this.formData.from = this.$fromEmailAddress
  },
  methods: {
     formHandler() {

       let formData = {
         email: this.formData.email,
         name: this.formData.name,
         phone: this.formData.phone,
         subject: this.formData.subject,
         message: this.formData.message
       }

      this.axios.post(this.$contactFormPostUrl, 
        formData,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      ).then(() => {
        console.log("Email sent")
        this.emailSent = true;
        this.formData = {
      name: '',
      email: '',
      phone: '',
      subject: '',
      message: ''
    };
      }).catch((error) => {
        console.log("Email error")
        console.log(error)
      })
    },
  }
}
</script>